@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Segoe UI";
    src: url('./fonts/Segoe\ UI.ttf');
}

@font-face {
    font-family: "Segoe UI Bold";
    src: url('./fonts/Segoe\ UI\ Bold.ttf');
}

@font-face {
    font-family: "Quick sand";
    src: url('./fonts/Quicksand-Regular.ttf');
}

@font-face {
    font-family: "Quick sand bold";
    src: url('./fonts/Quicksand-Bold.ttf');
}

/* -------------------------------------------------------- */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    /* background-color: rgb(231, 231, 231); */
    background-color: white;
}
.container{
    width: 80%;
    margin: auto;
}
.activeNav{
    background-color: #F5F5F5;
}
.activeDot{
    display: flex;
}

.circle
{
  width:100px;
  height:100px;
  background-color: white;
  border-radius: 50%;
}

.rec.rec-carousel-wrapper .rec-carousel button {
  background: white;
  color: black;
}

.rectangle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#host_screen_scroll::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}




.react-datepicker-wrapper{
    width:100%;
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 0.5rem;
    overflow-y: auto;
    padding: 10px;
  }
  
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }